<template>
  <div>
    <custom-table
      ref="customTable"
      :columns="columns"
      :custom-view="customView"
      :footer-actions="footerActions"
      :sums="sums"
      table-height="calc(100vh - 205px)"
      api-url="/payment-receipt-history"
      count-component="receipt-history-count"
    ></custom-table>
  </div>
</template>

<script>
import {
  CUSTOM_VIEW_ADMIN_PAYABLE,
  SUPPLIER_TYPE_OPTIONS,
  RECEIPT_STATUS_OPTIONS,
} from "@/libs/const";
export default {
  name: "ReceiptHistory",
  components: {},
  data: () => ({
    customView: CUSTOM_VIEW_ADMIN_PAYABLE,
    columns: [],
    footerActions: [],
    sums: [],
    isLoading: false,
  }),
  computed: {},
  created() {
    const supplierTypeOptions = [...SUPPLIER_TYPE_OPTIONS].map((opt) => ({
      text: this.$t(`labels.supplier_type_${opt}`),
      value: opt,
    }));
    const statusOptions = [...RECEIPT_STATUS_OPTIONS];
    const columns = [
      {
        type: "input-filter",
        label: this.$t("labels.supplier_code"),
        placeholder: this.$t("labels.supplier_code"),
        name: "supplier_code",
        hasSort: false,
        sortName: "supplier_code",
        key: "supplier_code",
        required: true,
      },
      {
        type: "select-filter",
        label: this.$t("labels.type"),
        placeholder: this.$t("labels.type"),
        name: "supplier_type",
        hasSort: false,
        sortName: "supplier_type",
        key: "supplier_type",
        options: supplierTypeOptions,
        required: true,
      },
      {
        type: "input-filter",
        label: this.$t("labels.name"),
        placeholder: this.$t("labels.name"),
        name: "supplier_name",
        hasSort: false,
        sortName: "supplier_name",
        key: "supplier_name",
        required: true,
      },
      {
        type: "input-filter",
        label: this.$t("labels.product_code"),
        placeholder: this.$t("labels.product_code"),
        name: "customer_goods_barcode",
        hasSort: false,
        sortName: "customer_goods_barcode",
        key: "customer_goods_barcode",
        required: true,
      },
      {
        type: "input-filter",
        label: this.$t("labels.product"),
        placeholder: this.$t("labels.product"),
        name: "goods_name",
        hasSort: false,
        sortName: "goods_name",
        key: "goods_name",
        required: true,
      },
      {
        type: "input-filter",
        label: this.$t("labels.receipt_session"),
        placeholder: this.$t("labels.receipt_session"),
        name: "tracking_id",
        hasSort: false,
        sortName: "tracking_id",
        key: "tracking_id",
        required: true,
      },
      {
        type: "select-filter",
        label: this.$t("labels.receipt_status"),
        placeholder: this.$t("labels.receipt_status"),
        name: "state",
        hasSort: false,
        sortName: "state",
        key: "state",
        options: statusOptions,
        required: true,
      },
      {
        type: "input-filter-from-to",
        label: this.$t("labels.request_quantity"),
        placeholder: this.$t("labels.request_quantity"),
        name: "request_quantity",
        hasSort: false,
        sortName: "request_quantity",
        key: "request_quantity",
        required: true,
      },
      {
        type: "input-filter-from-to",
        label: this.$t("labels.total_cost_1"),
        placeholder: this.$t("labels.total_cost_1"),
        name: "cost",
        hasSort: false,
        sortName: "cost",
        key: "cost",
        required: true,
      },
      {
        type: "input-filter-from-to",
        label: this.$t("labels.receipting_quantity"),
        placeholder: this.$t("labels.receipting_quantity"),
        name: "receipting_quantity",
        hasSort: false,
        sortName: "receipting_quantity",
        key: "receipting_quantity",
        required: true,
      },
      {
        type: "input-filter-from-to",
        label: this.$t("labels.receipting_debt"),
        placeholder: this.$t("labels.receipting_debt"),
        name: "receipting_debt",
        hasSort: false,
        sortName: "receipting_debt",
        key: "receipting_debt",
        required: true,
      },
      {
        type: "date-range-filter",
        label: this.$t("labels.first_receipt"),
        placeholder: this.$t("labels.first_receipt"),
        name: "first_handle_time",
        hasSort: false,
        sortName: "first_handle_time",
        key: "first_handle_time",
        required: true,
      },
      {
        type: "date-range-filter",
        label: this.$t("labels.finish_date"),
        placeholder: this.$t("labels.finish_date"),
        name: "finish_timestamp",
        hasSort: false,
        sortName: "finish_timestamp",
        key: "finish_timestamp",
        required: true,
      },
      {
        type: "input-filter-from-to",
        label: this.$t("labels.receipted_quantity"),
        placeholder: this.$t("labels.receipted_quantity"),
        name: "receipted_quantity",
        hasSort: false,
        sortName: "receipted_quantity",
        key: "receipted_quantity",
        required: true,
      },
      {
        type: "input-filter-from-to",
        label: this.$t("labels.receipted_debt"),
        placeholder: this.$t("labels.receipted_debt"),
        name: "receipted_debt",
        hasSort: false,
        sortName: "receipted_debt",
        key: "receipted_debt",
        required: true,
      },
    ];
    this.columns = [...columns];

    const footerActions = [];
    this.footerActions = [...footerActions];

    const sums = [];
    this.sums = [...sums];
  },
  mounted() {},
  methods: {},
};
</script>

<style scoped></style>
